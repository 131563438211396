import { useEffect, useState } from "react";
import styled from "styled-components";
import { useContextStore } from "../../context-store";
import { usePropertyValidationStore } from "../store";
import { token } from "@atlaskit/tokens";

const EditState = {
  VIEW: "view",
  EDIT: "edit",
};

const ViewContainerWrapper = styled.div`
  width: 100%;
  padding: 5px 0px;
  cursor: pointer;
  margin-left: 5px;
  padding-right: 18px;
  ${({ disableEdit }) =>
    !disableEdit &&
    `&:hover {
    background-color: ${token("elevation.surface.pressed")};
      }
    `};
`;

const InlineEditing = ({ View, Edit, onSave, onCancel, disableEdit = false, viewStyles = {} }) => {
  const [editState, setEditState] = useState(EditState.VIEW);
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);
  const { accountId } = useContextStore((state) => ({
    accountId: state.accountId,
  }));
  const { validValue } = usePropertyValidationStore((state) => ({
    validValue: state.validValue,
  }));
  if (editState === EditState.VIEW) {
    return (
      <ViewContainerWrapper
        style={viewStyles}
        disableEdit={accountId ? disableEdit : true}
        onClick={() => {
          if (disableEdit) {
            return;
          }
          if (!accountId) {
            return;
          }
          setEditState(EditState.EDIT);
        }}
      >
        {View}
      </ViewContainerWrapper>
    );
  }

  if (editState === EditState.EDIT) {
    return (
      <div
        style={{ width: "100%" }}
        onBlur={() => {
          if (validValue) {
            onSave();
            setEditState(EditState.VIEW);
          }
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter" && validValue) {
            onSave();
            setEditState(EditState.VIEW);
          }
          if (event.key === "Escape") {
            onCancel();
            setEditState(EditState.VIEW);
          }
        }}
      >
        {Edit}
      </div>
    );
  }
};

export default InlineEditing;
