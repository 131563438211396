import httpClient from "../http-client";

import atlassianConfluencePageCache from "./AtlassianConfluencePageCache";
import atlassianUserCache from "./AtlassianUserCache";

const FAKE_API_BASE_URL = "https://atlassian-fake-api.eu.ngrok.io";

const fakeApiIsEnabled = () => {
  return process.env.NODE_ENV === "development" && process.env.REACT_APP_ENABLE_FAKE_API === "1";
};

class AtlassianRestService {
  async get(url) {
    if (fakeApiIsEnabled()) {
      return (await httpClient.get(`${FAKE_API_BASE_URL}${url}`)).data;
    }
    return this.fetch(url);
  }

  async getCurrentUser() {
    return await this.fetch("/rest/api/user/current");
  }

  async getUserByAccountId(accountId) {
    try {
      const userFromCache = atlassianUserCache.get(accountId);
      if (userFromCache) {
        return userFromCache;
      }
      const freshUser = await this.get("/rest/api/user?accountId=" + accountId);
      atlassianUserCache.putIfAbsent(accountId, freshUser);
      return freshUser;
    } catch (e) {
      console.warn("Could not fetch user with accountId " + accountId + ". Returning null.");
      console.warn(e);
      return null;
    }
  }
  async searchUser(query) {
    if (fakeApiIsEnabled()) {
      return (await httpClient.get(`${FAKE_API_BASE_URL}/rest/api/search/user?q=${query}`)).data;
    }
    return this.fetch(`/rest/api/search/user?cql=user.fullname~'${query}'`);
  }

  async findUsersByName(query) {
    try {
      return await this.searchUser(query);
    } catch (e) {
      console.log("cant fetch users");
      console.log(e);
    }
  }

  async getPageById(pageId) {
    try {
      const pageFromCache = atlassianConfluencePageCache.get(pageId);
      if (pageFromCache) {
        return pageFromCache;
      }
      const page = await this.fetch("/rest/api/content/" + pageId);
      atlassianConfluencePageCache.putIfAbsent(pageId, page);
      return page;
    } catch (e) {
      console.warn("Could not fetch page with id " + pageId + ". Returning null.");
      console.warn(e);
      return null;
    }
  }

  async getLatestPageById(pageId) {
    try {
      const page = await this.fetch(`/rest/api/content/${pageId}/version`);
      const pageUrl = page.results[0]._links.self;
      const latestVersionId = pageUrl.substring(pageUrl.indexOf("content/") + 8, pageUrl.indexOf("/version"));
      return latestVersionId;
    } catch (e) {
      console.warn("Could not fetch latest page version with the id " + pageId + ". Returning null.");
      console.warn(e);
      return null;
    }
  }

  async userHasPermissionToEditPage(accountId, pageId) {
    try {
      const response = await this.fetch(`/rest/api/content/${pageId}/restriction`);
      const updateRestrictions = response.results.find((r) => r.operation === "update");
      if (updateRestrictions.restrictions.group.size === 0 && updateRestrictions.restrictions.user.size === 0) {
        // there are no restrictions on the page
        return true;
      }
    } catch (e) {
      console.warn("error fetching restrictions", e);
    }

    try {
      await window.AP.request(`/rest/api/content/${pageId}/restriction/byOperation/update/user?accountId=${accountId}`);
      return true;
    } catch (e) {
      return false;
    }
  }
  async searchForSpace(query) {
    const url = `/rest/api/search?cql=type=space and (space.title~"${query}*" or space.key IN ("${query}"))&limit=25&expand=space.icon`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("can't fetch spaces");
      console.log(e);
    }
  }  
  async searchForPage({ query, limit = 25 }) {
    const modifiedQuery = query?.replace(/[^a-zA-Z0-9 ]/g, " ");
    const encodedQuery = encodeURIComponent(query);
    const url = `/rest/api/search?cql=type in (page, blogpost) and (title~"${modifiedQuery}*")&limit=${limit}&expand=content.history,content.space.icon,content.space&excerpt=none`;
    const urlSecondApproach = `/rest/api/search?cql=type in (page, blogpost) and (title~"${encodedQuery} *")&limit=${limit}&expand=content.history,content.space.icon,content.space&excerpt=none`;

    try {
      const data = await this.fetch(url);
      if (data?.results?.length === 0) {
        const data2 = await this.fetch(urlSecondApproach);
        data?.results?.push(...data2?.results);
      }
      return data;
    } catch (e) {
      console.log("Can't fetch pages");
      console.log(e);
      return {
        results: [],
      };
    }
  }

  async getSpaceByKey(spaceKey) {
    const url = `/rest/api/space/${spaceKey}?expand=icon`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch spaces");
      console.log(e);
    }
  }
  async getGroupById(groupId) {
    const url = `/rest/api/group/by-id?id=${groupId}`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch group by id");
      console.log(e);
    }
  }
  async getGroupsByName(groupName) {
    const url = `/rest/api/group/picker?query=${groupName}`;
    try {
      return await this.fetch(url);
    } catch (e) {
      console.log("cant fetch group by id");
      console.log(e);
    }
  }
  async getIconOfContent(contentId) {
    const url = `/rest/api/content/${contentId}/property/emoji-title-published`;
    try {
      const response = await window.AP.request(url);
      const body = JSON.parse(response.body);
      return body;
    } catch (e) {
      console.error("Error fetching atlassian response", e);
      return;
    }
  }
  getProfilePictureUrl(path) {
    if (fakeApiIsEnabled()) {
      return path;
    }
    const hostUrl = window.AP._hostOrigin;
    if (hostUrl === null) {
      console.error("No AP object on window");
    }
    return hostUrl + path;
  }

  async fetch(path) {
    const AP = window.AP;
    if (!AP) {
      console.error("No AP object on window. Cant fetch " + path);
      return null;
    }
    const response = await AP.request(path);
    try {
      return JSON.parse(response.body);
    } catch (e) {
      console.error("Error fetching atlassian response", e);
      return null;
    }
  }
}

export const atlassianRestService = new AtlassianRestService();
